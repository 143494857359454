import { graphql } from "gatsby";
import Img from "gatsby-image";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import gsap from "gsap";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Colors from "src/styles/Colors";
import media from "src/styles/media";
import { Title1, Paragraph1, Paragraph3 } from "src/components/Text";

import { ReactComponent as MobileGraphSVG } from "svg/rtfkt/mobilePinkGraph.svg";
import { ReactComponent as GraphSVG } from "svg/rtfkt/pinkGraph.svg";
import { ReactComponent as RtfktXStapleSVG } from "svg/rtfkt/logos-sideways.svg";
import { ReactComponent as LogosSVG } from "svg/rtfkt/logos.svg";

const RtfktPage = ({ data }) => {
  return (
    <Layout minimalBranding>
      <SEO title="RTFKT" />
      <Helmet>
        <script
          type="module"
          src="https://cdn.bitskistatic.com/bitski-ui/latest/dist/bitski-ui/bitski-ui.esm.js"
        ></script>

        <meta
          property="og:description"
          content="The Staple Pigeon and Jeff Staple now enters the meta-verse for the first time ever with RTFKT Studios as their trusted guides into this brave new world."
        />
        <meta
          property="og:image"
          content="https://cdn.bitskistatic.com/tokens-raw/15a13105-cdf2-40e0-b8c5-8ffdbb155c77/34499ad6-805c-4148-849c-d475b3254b31.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
        <meta
          property="og:video"
          content="https://cdn.bitskistatic.com/tokens-raw/15a13105-cdf2-40e0-b8c5-8ffdbb155c77/00a5231e-03dc-4c7c-918c-81833702ee88.mp4"
        />
        <meta property="og:video:type" content="video/mp4" />
        <meta property="og:video:width" content="640" />
        <meta property="og:video:height" content="360" />

        <meta name="twitter:title" content="RTFKT | Bitski" />
        <meta name="twitter:card" content="player" />
        <meta name="twitter:site" content="@bitski" />
        <meta
          name="twitter:description"
          content="The Staple Pigeon and Jeff Staple now enters the meta-verse for the first time ever with RTFKT Studios as their trusted guides into this brave new world."
        />
        <meta
          name="twitter:image"
          content="https://cdn.bitskistatic.com/tokens-raw/15a13105-cdf2-40e0-b8c5-8ffdbb155c77/34499ad6-805c-4148-849c-d475b3254b31.png"
        />
        <meta
          name="twitter:player"
          content="https://cdn.bitskistatic.com/tokens-raw/15a13105-cdf2-40e0-b8c5-8ffdbb155c77/00a5231e-03dc-4c7c-918c-81833702ee88.mp4"
        />
        <meta name="twitter:player:width" content="640" />
        <meta name="twitter:player:height" content="360" />
      </Helmet>
      <VerticalLogos />
      <Rtfkt data={data} />
    </Layout>
  );
};

const VerticalLogos = styled(RtfktXStapleSVG)`
  position: absolute;
  left: 0;
  top: 0;
  width: 5vw;
  top: 3.8vw;
  ${media.tablet} {
    display: none;
  }
`;

const Rtfkt: React.FC<{ data: any }> = ({ data }) => {
  const pigeonCable: any = useRef();
  const pigeonBlade: any = useRef();
  const pigeonBird: any = useRef();
  const wrapper: any = useRef();

  const parallaxIt = (e, target, movement) => {
    const container = wrapper.current;
    const relX = e.pageX - container.offsetLeft;
    const relY = e.pageY - container.offsetTop;

    gsap.to(target, {
      x:
        ((relX - container.offsetWidth / 2) / container.offsetWidth) * movement,
      y:
        ((relY - container.offsetHeight / 2) / container.offsetHeight) *
        movement,
      ease: "Power2.easeOut",
    });
  };

  const onMove = (e) => {
    parallaxIt(e, pigeonCable.current, -60);
    parallaxIt(e, pigeonBlade.current, -50);
    parallaxIt(e, pigeonBird.current, -40);
    parallaxIt(e, "#graph", -10);
  };

  return (
    <Background>
      <HeroWrapper ref={wrapper} onMouseMove={onMove}>
        <Graph id="graph" />
        <MobileGraph />
        <HeroTextWrapper>
          <Logos />
          <Title>The new sneaker meta is here</Title>
          <HeroParagraph>
            The Staple Pigeon and Jeff Staple now enters the meta-verse for the
            first time ever with RTFKT Studios as their trusted guides into this
            brave new world.
          </HeroParagraph>
        </HeroTextWrapper>
        <PigeonCable ref={pigeonCable}>
          <Img fluid={data.pigeonCable.childImageSharp.fluid} />
        </PigeonCable>
        <PigeonBlade ref={pigeonBlade}>
          <Img fluid={data.pigeonBlade.childImageSharp.fluid} />
        </PigeonBlade>
        <PigeonBird ref={pigeonBird}>
          <Img fluid={data.pigeonBird.childImageSharp.fluid} />
        </PigeonBird>
        <Products>
          <ProductWrapper>
            <Product>
              <a href="https://www.bitski.com/rtfkt/products/2534ed6a-f4dc-4c6e-b17a-1df9d7a0a552">
                <product-viewer
                  title-color="#eb4a97"
                  price-color="#fbfbfb"
                  product-id="2534ed6a-f4dc-4c6e-b17a-1df9d7a0a552"
                  display-type="minimal"
                  currency="USD"
                ></product-viewer>
              </a>
            </Product>
          </ProductWrapper>
          <ProductWrapper>
            <Product>
              <a href="https://www.bitski.com/rtfkt/products/0daa5501-2b46-4e63-ac40-d71799c11b18">
                <product-viewer
                  title-color="#eb4a97"
                  price-color="#fbfbfb"
                  product-id="0daa5501-2b46-4e63-ac40-d71799c11b18"
                  display-type="minimal"
                  currency="USD"
                ></product-viewer>
              </a>
            </Product>
          </ProductWrapper>
          <ProductWrapper>
            <Product>
              <a href="https://www.bitski.com/rtfkt/products/38918370-f930-4b05-9f0f-6734c13121d0">
                <product-viewer
                  title-color="#eb4a97"
                  price-color="#fbfbfb"
                  product-id="38918370-f930-4b05-9f0f-6734c13121d0"
                  display-type="minimal"
                  currency="USD"
                ></product-viewer>
              </a>
            </Product>
          </ProductWrapper>
        </Products>
      </HeroWrapper>
      <BodyWrapper>
        <BodyParagraph>
          The NY Times said it best: “Jeff Staple is often credited with
          kick-starting the collectible sneaker revolution.” The history of Jeff
          Staple’s “Pigeon” icon is one of the most — if not THE MOST —
          celebrated stories in all of sneaker culture. Dating back to the late
          90s, Staple has been an instrumental figurehead in the culture and
          still innovates to this very day.
        </BodyParagraph>
        <BodyParagraph>
          A Size 11 Nike Pigeon Dunk on StockX currently goes for $96,600! Even
          his collaboration with the most non-hyped brand—Allbirds, saw pairs
          being flipped on StockX. Multiple documentaries have been made about
          this legendary Pigeon. And finally cementing "The Pigeon" into the
          fine art world; the legendary auction house Sotheby’s recently
          auctioned off a pair.
        </BodyParagraph>
        <BodyParagraph>
          The Staple Pigeon and Jeff Staple now enters the meta-verse for the
          first time ever with RTFKT Studios as their trusted guides into this
          brave new world. This is the first time we are seeing real world
          sneakerhead communities colliding with crypto art collectors. Get
          ready to witness history in the making!
        </BodyParagraph>
      </BodyWrapper>
    </Background>
  );
};

const Background = styled.div`
  background: radial-gradient(
    42.93% 34.94% at 72.99% 27.03%,
    #111644 0%,
    #000000 100%
  );
`;

const Logos = styled(LogosSVG)`
  width: 28vw;
  ${media.tablet} {
    width: 60vw;
  }
`;

const PigeonCable = styled.div`
  position: absolute;
  width: 50vw;
  left: 45vw;
  top: 2vw;
  ${media.tablet} {
    width: 100vw;
    top: 100vw;
    left: 0vw;
  }
`;

const PigeonBlade = styled.div`
  position: absolute;
  width: 50vw;
  left: 45vw;
  top: 2vw;
  ${media.tablet} {
    width: 100vw;
    top: 100vw;
    left: 0vw;
  }
`;

const PigeonBird = styled.div`
  position: absolute;
  width: 50vw;
  left: 45vw;
  top: 2vw;
  ${media.tablet} {
    width: 100vw;
    top: 100vw;
    left: 0vw;
  }
`;

const HeroWrapper = styled.section`
  position: relative;
  padding: 0 6.9vw;
  height: 100vw;
  overflow: hidden;
  ${media.tablet} {
    padding: 0 4.8vw;
    height: 569vw;
  }
`;
const Graph = styled(GraphSVG)`
  position: absolute;
  width: 97.08vw;
  top: -16.3vw;
  left: 21.8vw;
  color: ${Colors.yellow};
  transform: translate3d(0, 0, 0);
  ${media.tablet} {
    display: none;
  }
`;
const MobileGraph = styled(MobileGraphSVG)`
  position: absolute;
  top: 78.7vw;
  right: 0;
  display: none;
  width: 89.4vw;
  color: ${Colors.yellow};

  ${media.tablet} {
    display: block;
  }
`;
const HeroTextWrapper = styled.div`
  position: relative;
  width: 51vw;
  padding-top: 16vw;
  ${media.tablet} {
    width: 100%;
  }
`;
const Title = styled(Title1)`
  color: white;
  margin-top: 2.2vw;
  ${media.tablet} {
    margin-top: 6.2vw;
    font-weight: normal;
  }
`;
const HeroParagraph = styled(Paragraph1)`
  width: 33.8vw;
  margin-top: 1vw;
  ${media.tablet} {
    width: 81.2vw;
    margin-top: 7.2vw;
    font-size: 3.9vw;
  }
`;
const Products = styled.div`
  position: relative;
  margin: 25vw auto 10vw;
  border: solid 1px #4aeba8;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  padding: 3vw;
  ${media.tablet} {
    margin-top: 120vw;
    border: none;
    display: block;
    width: 100%;
  }
`;
const ProductWrapper = styled.div`
  width: 24vw;
  height: 23vw;
  position: relative;
  ${media.tablet} {
    width: 100%;
    height: auto;
  }
`;
const Product = styled.div`
  position: absolute;
  width: 100%;
  min-height: 350px;
  max-height: 30vw;
  top: -7vw;
  background-color: black;
  padding: 2vw;
  border: solid 1px #4aeba8;
  border-radius: 10px;
  ${media.tablet} {
    max-height: 110vw;
    position: relative;
    padding: 4vw;
    margin-top: 8vw;
  }
`;
const BodyWrapper = styled.section`
  position: relative;
  padding: 0 6.9vw 8vw;
  ${media.tablet} {
    padding: 0 4.8vw 15vw;
    display: block;
  }
`;
const BodyParagraph = styled(Paragraph3)`
  width: 50vw;
  margin: 1vw auto;
  line-height: 200%;
  ${media.tablet} {
    width: 81.2vw;
    margin-top: 7.2vw;
    font-size: 3.9vw;
  }
`;

export const pageQuery = graphql`
  query {
    pigeonCable: file(relativePath: { eq: "rtfkt/pigeon-cable.png" }) {
      ...fluidImage
    }
    pigeonBlade: file(relativePath: { eq: "rtfkt/pigeon-blade.png" }) {
      ...fluidImage
    }
    pigeonBird: file(relativePath: { eq: "rtfkt/pigeon-bird.png" }) {
      ...fluidImage
    }
    lineGrid: file(relativePath: { eq: "rtfkt/lineGrid.png" }) {
      ...fluidImage
    }
  }
`;

export default RtfktPage;
